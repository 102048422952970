<style lang="less" scoped>
.design-product {
  width: 100%;
  min-height: 800px;
  padding-top: 130px;
  background: #070911;
  position: relative;
  &-header {
    width: 100%;
    max-width: 1104px;
    height: 60px;
    margin: 0 auto;
    background: rgb(15, 17, 24);
    display: flex;
    justify-content: flex-start;
    &-item {
      width: 150px;
      line-height: 60px;
      text-align: center;
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      color: rgba(222, 230, 255, 1);
    }
    .actived {
      font-size: 14px;
      font-weight: 600;
      color: rgba(85, 119, 224, 1);
      background: rgba(30, 33, 43, 1);
      border-bottom: 2px solid rgba(85, 119, 224, 1);
    }
  }
  &-main {
    width: 100%;
    height: 100%;
    min-height: 800px;
    max-width: 1104px;
    margin: 0 auto;
    margin-top: 50px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &-item {
      width: 30%;
      max-width: 348px;
      height: 471px;
      background: #0f1118;
      position: relative;
      margin-top: 16px;
      color: rgb(255, 255, 255);
      overflow: scroll;
      cursor: pointer;
      position: relative;
      &-top {
        width: 100%;
        height: 180px;
        .preview-png {
          width: 100%;
          height: 100%;
          background: rgba(7, 9, 17, 0.59);
        }
      }
      &-bottom {
        width: 80%;
        margin: 16% 10% 0;
        text-align: left;
        p:nth-child(1) {
          font-size: 20px;
          font-weight: 400;
          color: #dee6ff;
          line-height: 28px;
        }
        p:nth-child(2) {
          font-size: 14px;
          font-weight: 400;
          color: #dee6ff;
          line-height: 24px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="design-product">
    <div class="design-product-header">
      <div class="design-product-header-item"
           v-for="(item, index) in designProductNav"
           :key="index"
           @click="changeModal(item.type)"
           :class="index === activedIndex ? 'actived' : ''">
        <span>
          {{ item.name }}
        </span>
      </div>
    </div>
    <div class="design-product-main">
      <div class="design-product-main-item"
           v-for="(item, index) in awardWorkList"
           :key="index"
           @click="toCompanyHome(item)">
        <div class="design-product-main-item-top"
             v-if="item.detail.previewUrl">
          <img :src="'/api/static/'+item.detail.previewUrl"
               alt=""
               class="preview-png">
        </div>
        <div class="design-product-main-item-bottom">
          <p>
            {{ item.name }}
          </p>
          <p>
            {{ item.detail.explain || item.detail.dingwei }}
          </p>
        </div>
      </div>
    </div>
    <Common-activies :style="{maxWidth: '1104px', margin: '0 auto'}"></Common-activies>
    <Common-footer></Common-footer>
  </div>
</template>

<script>
import CommonActivies from '../components/common-activities-c';
import CommonFooter from '../components/common-footer';

export default {
  name: 'design-product',
  data () {
    return {
      activedIndex: 0,
      designProductType: 'guyan',
      designProductNav: [
        {
          name: '古堰画乡',
          type: 'guyan'
        },
        {
          name: '东方美谷',
          type: 'dongfang'
        },
        {
          name: '南翔智地',
          type: 'nanxiang'
        }
      ],
      awardWorkList: []
    }
  },
  created () {
    this.changeModal(this.$route.params.type);
  },
  mounted () {
  },
  watch: {
  },
  methods: {
    linkTo (url, type) {
      if (type) {
        window.open(url, type);
      } else {
        this.$router.push({
          name: url
        })
      }
    },
    toCompanyHome (item) {
      console.log(item);
      // if (!this.$Cookies.get('cecc-user')) {
      //   this.$router.push({
      //     name: 'login',
      //     params: {
      //       redirect: 'fairviewJiangnan'
      //     }
      //   })
      // } else {
      //   this.$router.push({
      //     name: 'companyPreview',
      //     params: {
      //       from: 'jiangnan',
      //       price: this.designProductType,
      //       brandObj: item.detail
      //     }
      //   })
      // }
    },
    changeModal (type) {
      this.designProductType = type;
      this.activedIndex = this.designProductNav.findIndex(item => item.type === type);
      this.getSelection();
    },
    getSelection () {
      this.$api.getDesign_work_prize_list({
        // module: this.module,
        category: this.designProductType
      }).then(res => {
        this.awardWorkList = res.works;
      });
    }
  },
  components: {
    CommonActivies,
    CommonFooter
  }
}
</script>
